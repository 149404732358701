<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
        <v-card color="#48A8F8" class="px-7 py-10 rounded-lg">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                {{ currentDate | dateDayDisplay }}
              </p>
              <p class="subtitle-2 white--text ma-0">
                {{ currentDate | date }}
              </p>
            </div>
            <v-avatar class="ma-1" tile>
              <v-icon size="70" color="white">event</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
        <v-card color="#FBB005" class="px-7 py-10 rounded-lg">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                {{ summary.totalPegawai | number }}
              </p>
              <p class="subtitle-2 white--text ma-0">Jumlah Pegawai Aktif</p>
            </div>
            <v-avatar class="ma-1" tile>
              <v-icon size="70" color="white">person</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
        <v-card color="#3AD581" class="px-7 py-10 rounded-lg">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                {{ summary.totalPension | number }}
              </p>
              <p class="subtitle-2 white--text ma-0">
                Perkiraan Pegawai Pensiun
              </p>
            </div>
            <v-avatar class="ma-1" tile>
              <v-icon size="70" color="white">person</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
        <v-card color="#ED7272" class="px-7 py-10 rounded-lg">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                {{ summary.totalPNS | number }}
              </p>
              <p class="subtitle-2 white--text ma-0">Total Pegawai PNS</p>
            </div>
            <v-avatar class="ma-1" tile>
              <v-icon size="70" color="white">person</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
        <v-autocomplete
          :items="units"
          v-model="grafikFilter.unit"
          :loading="loadingUnit"
          placeholder="Unit Utama"
          outlined
          dense
          hide-details
          item-text="singkatan"
          item-value="unit_utama_id"
          clearable
          @change="changeUnit(1, 'grafik')"
          @click:clear="changeUnit(1, 'grafik')"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
        <v-autocomplete
          :items="grafikList.units2"
          v-model="grafikFilter.unit2"
          :loading="loadingUnit"
          placeholder="Unit Kerja"
          outlined
          dense
          hide-details
          item-text="singkatan"
          item-value="unit_kerja_2_id"
          clearable
          @change="changeUnit(2, 'grafik')"
          @click:clear="changeUnit(2, 'grafik')"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
        <v-autocomplete
          :items="grafikList.units3"
          v-model="grafikFilter.unit3"
          :loading="loadingUnit"
          placeholder="Unit Kerja"
          outlined
          dense
          hide-details
          item-text="singkatan"
          item-value="unit_kerja_3_id"
          clearable
          @change="changeUnit(3, 'grafik')"
          @click:clear="changeUnit(3, 'grafik')"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
        <v-autocomplete
          :items="grafikList.units4"
          v-model="grafikFilter.unit4"
          :loading="loadingUnit"
          placeholder="Unit Kerja"
          outlined
          dense
          hide-details
          item-text="singkatan"
          item-value="unit_kerja_4_id"
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <pie-single-chart ref="pieSingleJenisKelamin" />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <pie-single-chart ref="pieSingleUsia" />
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <pie-single-chart ref="pieSingleJabatan" />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <pie-single-chart ref="pieSinglePendidikan" />
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <pie-single-chart ref="pieSingleSatker" />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <pie-single-chart ref="pieSingleMasaKerja" />
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <pie-single-chart ref="pieSingleStatusPegawai" />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <pie-single-chart ref="pieSingleStatusKepegawaian" />
      </v-col>
    </v-row>
    <v-card id="card-custom" class="pa-7 rounded-lg ma-4">
      <p class="headline-color px-2">Rekapitulasi</p>
      <v-divider />
      <v-row>
        <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
          <v-select
            placeholder="Komponen"
            :items="listFilter"
            v-model="filter"
            outlined
            dense
            hide-details
            clearable
            item-text="name"
            item-value="code"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
          <v-autocomplete
            :items="units"
            v-model="rekapitulasiFilter.unit"
            :loading="loadingUnit"
            placeholder="Unit Utama"
            outlined
            dense
            hide-details
            item-text="singkatan"
            item-value="unit_utama_id"
            clearable
            @change="changeUnit(1, 'rekapitulasi')"
            @click:clear="changeUnit(1, 'rekapitulasi')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
          <v-autocomplete
            :items="rekapitulasiList.units2"
            v-model="rekapitulasiFilter.unit2"
            :loading="loadingUnit"
            placeholder="Unit Kerja"
            outlined
            dense
            hide-details
            item-text="singkatan"
            item-value="unit_kerja_2_id"
            clearable
            @change="changeUnit(2, 'rekapitulasi')"
            @click:clear="changeUnit(2, 'rekapitulasi')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
          <v-autocomplete
            :items="rekapitulasiList.units3"
            v-model="rekapitulasiFilter.unit3"
            :loading="loadingUnit"
            placeholder="Unit Kerja"
            outlined
            dense
            hide-details
            item-text="singkatan"
            item-value="unit_kerja_3_id"
            clearable
            @change="changeUnit(3, 'rekapitulasi')"
            @click:clear="changeUnit(3, 'rekapitulasi')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
          <v-autocomplete
            :items="rekapitulasiList.units4"
            v-model="rekapitulasiFilter.unit4"
            :loading="loadingUnit"
            placeholder="Unit Kerja"
            outlined
            dense
            hide-details
            item-text="singkatan"
            item-value="unit_kerja_4_id"
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        id="table-custom"
        :headers="headers"
        :items="summaries"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DataUtamaService from "@/services/resources/data.utama.service";
import PieSingleChart from "@/components/Chart/PieSingle";
import DashboardService from "@/services/resources/dashboard.service";

export default {
  components: {
    PieSingleChart
  },
  data() {
    return {
      menu: false,
      currentDate: new Date(),
      date: new Date().toISOString().substr(0, 7),
      loading: false,
      loadingUnit: false,
      loadingChart: false,
      loadingFilter: false,
      summary: {
        totalPegawai: 0,
        totalPension: 0,
        totalPNS: 0
      },
      grafikFilter: {
        unit: null,
        unit2: null,
        unit3: null,
        unit4: null
      },
      grafikList: {
        units2: [],
        units3: [],
        units4: []
      },
      rekapitulasiFilter: {
        unit: null,
        unit2: null,
        unit3: null,
        unit4: null
      },
      rekapitulasiList: {
        units2: [],
        units3: [],
        units4: []
      },
      units: [],
      filter: null,
      listFilter: [],
      headers: [
        {
          text: "Nomor",
          value: "no",
          sortable: false
        },
        {
          text: "Keterangan",
          value: "keterangan",
          sortable: false
        },
        {
          text: "Jumlah",
          value: "jumlah",
          sortable: false
        }
      ],
      summaries: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 4,
        pageCount: 1
      },
      options: {
        sortBy: ["eselon_id"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40]
    };
  },
  watch: {
    filterGrafik: function() {
      this.getGrafik1();
      this.getGrafik2();
      this.getGrafik3();
    },
    filterRekapitulasi: function() {
      this.getRekapitulasi();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    filterGrafik() {
      return [
        this.grafikFilter.unit,
        this.grafikFilter.unit2,
        this.grafikFilter.unit3,
        this.grafikFilter.unit4
      ].join();
    },
    filterRekapitulasi() {
      return [
        this.filter,
        this.rekapitulasiFilter.unit,
        this.rekapitulasiFilter.unit2,
        this.rekapitulasiFilter.unit3,
        this.rekapitulasiFilter.unit4
      ].join();
    }
  },
  methods: {
    changeUnit(type = 2, section) {
      if (!section) return;
      if (type == 1) {
        this[`${section}List`].units2 = this.units.find(
          d => d.unit_utama_id == this[`${section}Filter`].unit
        )?.unit_kerja2;
        this[`${section}Filter`].unit2 = null;
        this[`${section}Filter`].unit3 = null;
        this[`${section}Filter`].unit4 = null;
      } else if (type == 2) {
        this[`${section}List`].units3 = this[`${section}List`].units2.find(
          d => d.unit_kerja_2_id == this[`${section}Filter`].unit2
        )?.unit_kerja3;
        this[`${section}Filter`].unit3 = null;
        this[`${section}Filter`].unit4 = null;
      } else if (type == 3) {
        this[`${section}List`].units4 = this[`${section}List`].units3.find(
          d => d.unit_kerja_3_id == this[`${section}Filter`].unit3
        )?.unit_kerja4;
        this[`${section}Filter`].unit4 = null;
      }
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getGrafik1() {
      try {
        this.loadingChart = true;
        await DashboardService.getGrafik1({
          filter: {
            unit_utama_id: this.grafikFilter.unit,
            unit_kerja_2_id: this.grafikFilter.unit2,
            unit_kerja_3_id: this.grafikFilter.unit3,
            unit_kerja_4_id: this.grafikFilter.unit4
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              const { GrafikKelamin, GrafikUsia, GrafikStatusPegawai } = data;
              this.$refs.pieSingleJenisKelamin.init(
                "Jenis Kelamin",
                GrafikKelamin
              );
              this.$refs.pieSingleUsia.init("Umur", GrafikUsia);
              GrafikStatusPegawai.map(d => {
                if (d.label == "PNS") {
                  this.summary.totalPNS = d.value;
                }
              });
              this.$refs.pieSingleStatusKepegawaian.init(
                "Status Kepegawaian",
                GrafikStatusPegawai
              );
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingChart = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getGrafik2() {
      try {
        this.loadingChart = true;
        await DashboardService.getGrafik2({
          filter: {
            unit_utama_id: this.grafikFilter.unit,
            unit_kerja_2_id: this.grafikFilter.unit2,
            unit_kerja_3_id: this.grafikFilter.unit3,
            unit_kerja_4_id: this.grafikFilter.unit4
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              const {
                GrafikGroupJabatan,
                GrafikBySatker,
                GrafikPendidikan,
                GrafikMasaKerja
              } = data;
              this.$refs.pieSingleJabatan.init("Jabatan", GrafikGroupJabatan);
              this.$refs.pieSingleSatker.init("Satker", GrafikBySatker);
              this.$refs.pieSinglePendidikan.init(
                "Pendidikan",
                GrafikPendidikan
              );
              this.$refs.pieSingleMasaKerja.init("Masa Kerja", GrafikMasaKerja);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingChart = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getGrafik3() {
      try {
        this.loadingChart = true;
        await DashboardService.getGrafik3({
          filter: {
            unit_utama_id: this.grafikFilter.unit,
            unit_kerja_2_id: this.grafikFilter.unit2,
            unit_kerja_3_id: this.grafikFilter.unit3,
            unit_kerja_4_id: this.grafikFilter.unit4
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              data.map(d => {
                if (d.label == "Aktif") {
                  this.summary.totalPegawai = d.value;
                } else if (d.label == "Pensiun") {
                  this.summary.totalPension = d.value;
                }
              });
              this.$refs.pieSingleStatusPegawai.init("Status Pegawai", data);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingChart = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListRFilter() {
      try {
        this.loadingFilter = true;
        await DashboardService.getListRFilter()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.listFilter = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingFilter = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getRekapitulasi() {
      try {
        this.loading = true;
        await DashboardService.getRekapitulasi({
          filter: {
            jenis_filter: this.filter,
            unit_utama_id: this.rekapitulasiFilter.unit,
            unit_kerja_2_id: this.rekapitulasiFilter.unit2,
            unit_kerja_3_id: this.rekapitulasiFilter.unit3,
            unit_kerja_4_id: this.rekapitulasiFilter.unit4
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              let summaries = data;
              summaries.map((data, index) => {
                data.no = index + 1;
                return data;
              });
              this.summaries = summaries;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnit();
    this.getGrafik1();
    this.getGrafik2();
    this.getGrafik3();
    this.getListRFilter();
  }
};
</script>
