<template>
  <v-simple-table height="300px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            NIP
          </th>
          <th class="text-left">
            Nama
          </th>
          <th class="text-left">
            Jabatan
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in employees" :key="index">
          <td>{{ data.NIP }}</td>
          <td>{{ data.nama }}</td>
          <td>{{ data.nama_jabatan }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  props: {
    employees: { type: Array, required: false }
  }
};
</script>
