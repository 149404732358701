<template>
  <div>
    <DashboardUser v-if="isUserRole" />
    <DashboardAdmin v-else-if="isPresensi & isUserRole" />
    <DashboardAdminSimpegV2 v-else />
  </div>
</template>
<script>
import DashboardAdmin from "./DashboardAdmin";
import DashboardAdminSimpegV2 from "./DashboardAdminSimpegV2";
import DashboardUser from "./DashboardUser";
import { mapSummaryRootField } from "@/store/helpers";

import { mapGetters } from "vuex";
export default {
  components: {
    DashboardAdmin,
    DashboardAdminSimpegV2,
    DashboardUser
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isUserRole() {
      return this.currentUser.role === "USER";
    },
    ...mapSummaryRootField({
      isPresensi: "setting.isPresensi"
    })
  }
};
</script>
