<template>
  <div :id="`pie-chart`">
    <div id="chart-container">
      <fusioncharts
        :type="type"
        :width="width"
        :height="height"
        :dataformat="dataFormat"
        :dataSource="dataSource"
      >
      </fusioncharts>
    </div>
  </div>
</template>
<script>
import FusionCharts from "fusioncharts";
export default {
  props: {
    onClickPlot: {
      type: Function,
      required: false
    }
  },
  name: "app",
  data() {
    return {
      id: "",
      type: "doughnut2d",
      renderAt: "chart-container",
      width: "100%",
      height: "450",
      dataFormat: "json",
      dataSource: {
        chart: {
          bgColor: "#ffffff",
          startingAngle: "310",
          showLegend: "1",
          defaultCenterLabel: "",
          plottooltext: "$label<br>$value orang, $percentValue",
          centerLabelBold: "1",
          decimals: "2",
          theme: "fusion"
        },
        data: []
      },
      listen: true,
      remove: false
    };
  },
  methods: {
    init(val, title, id) {
      this.id = id;
      this.dataSource.data = val;
      this.dataSource.chart.defaultCenterLabel = title;
      if (this.onClickPlot) {
        FusionCharts.addEventListener("dataPlotClick", this.handleClick);
      }
    },
    handleClick(e) {
      this.onClickPlot(e, this.id);
    }
  }
};
</script>
