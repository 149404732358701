<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
        <v-card color="#48A8F8" class="px-7 py-10 rounded-lg">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                {{ dateNow | dateDayDisplay }}
              </p>
              <p class="subtitle-2 white--text ma-0">
                {{ dateNow | date }}
              </p>
              <p class="subtitle-2 white--text ma-0">.</p>
            </div>
            <v-avatar class="ma-1" tile>
              <v-icon size="70" color="white">event</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
        <v-card
          color="#FBB005"
          class="px-7 py-10 rounded-lg hovered"
          @click="handleCheckIn"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                Presensi
              </p>
              <p class="subtitle-2 white--text ma-0">
                <span v-if="attendanceData.status1 != 100001">
                  Untuk isi presensi klik disini
                </span>
                <span v-else>
                  Hari libur
                </span>
              </p>
              <p class="subtitle-2 white--text ma-0">
                <span
                  >Jam Kerja: <b>{{ attendanceData.jam_kerja }}</b></span
                >
              </p>
            </div>
            <v-avatar class="ma-1">
              <v-icon size="70" color="white">fingerprint</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
        <v-card
          color="#3AD581"
          class="px-7 py-10 rounded-lg hovered"
          @click="() => handleHistory('Riwayat Kehadiran', summary.presentList)"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                {{ summary.totalPresent | number }}
              </p>
              <p class="subtitle-2 white--text ma-0">
                Total Kehadiran di bulan
                <b>{{ dateNow | dateMonthDisplay }} {{ dateNow | dateYear }}</b>
              </p>
              <p class="subtitle-2 white--text ma-0">
                Untuk lihat riwayat klik disini
              </p>
            </div>
            <v-avatar class="ma-1">
              <v-icon size="70" color="white">check_circle_outline</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
        <v-card
          color="#ED7272"
          class="px-7 py-10 rounded-lg hovered"
          @click="
            () => handleHistory('Riwayat Ketidakhadiran', summary.absentList)
          "
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                {{ summary.totalAbsent | number }}
              </p>
              <p class="subtitle-2 white--text ma-0">
                Total Ketidakhadiran di bulan
                <b>{{ dateNow | dateMonthDisplay }} {{ dateNow | dateYear }}</b>
              </p>
              <p class="subtitle-2 white--text ma-0">
                Untuk lihat riwayat klik disini
              </p>
            </div>
            <v-avatar class="ma-1">
              <v-icon size="70" color="white">highlight_off</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <AbsenDialog @on-refresh="getDetailSchedule" ref="absen" />
    <ListAttendanceDialog ref="history" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PegawaiService from "@/services/resources/pegawai.service";
const AbsenDialog = () => import("@/components/Dialog/Absen");
const ListAttendanceDialog = () => import("@/components/Dialog/ListAttendance");

export default {
  components: {
    AbsenDialog,
    ListAttendanceDialog
  },
  data() {
    return {
      summary: {
        totalPresent: 0,
        totalAbsent: 0,
        totalDay: 0,
        presentList: [],
        absentList: []
      },
      attendanceData: {
        NIP: null,
        check_in_time: null,
        check_out_time: null,
        jam_kerja: null,
        jam_kerja_from: null,
        jam_kerja_to: null,
        status1: null,
        status1_name: null
      },
      dateNow: this.$moment()
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isAroundWorkingHour() {
      const { jam_kerja_from, jam_kerja_to } = this.attendanceData;
      if (!jam_kerja_from || !jam_kerja_to) return;
      const isAfterNow = this.$moment().isAfter(
        `${this.$moment().format("YYYY-MM-DD")} ${jam_kerja_from}`
      );
      const isBeforeNow = this.$moment().isBefore(
        `${this.$moment().format("YYYY-MM-DD")} ${jam_kerja_to}`
      );
      return isAfterNow && isBeforeNow;
    }
  },
  methods: {
    handleCheckIn() {
      this.$refs.absen.insert({
        NIP: this.currentUser.NIP,
        nama: this.currentUser.name,
        attendance_date: this.$moment(this.dateNow).format("YYYY-MM-DD")
      });
    },
    handleHistory(title, list) {
      this.$refs.history.view({
        title,
        historyAttendances: list
      });
    },
    // Service
    async getDetailSchedule(isFirst) {
      try {
        this.loadingUnit = true;
        await PegawaiService.getDetailSchedule(
          this.currentUser.NIP,
          this.$options.filters.dateMonth(this.dateNow),
          this.$options.filters.dateYear(this.dateNow)
        )
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              const { data_schedule } = data;
              const schedulesNow = data_schedule.find(
                item =>
                  item.attendance_date ==
                  this.$moment(this.dateNow).format("YYYY-MM-DD")
              );
              if (schedulesNow) {
                const [from, to] = schedulesNow.jam_kerja.split("-");
                this.attendanceData = this.$_.merge(this.attendanceData, {
                  ...schedulesNow,
                  jam_kerja_from: from,
                  jam_kerja_to: to
                });
              }
              const schedules = data_schedule.filter(
                item =>
                  item.status1 != 100001 &&
                  (item.schedule_type == "WFO" || item.schedule_type == "WFH")
              );
              const schedulesBeforeToday = schedules.filter(item =>
                this.$moment(item.attendance_date).isBefore(this.dateNow)
              );
              this.summary.totalDay = schedulesBeforeToday.length;

              // calculate present list
              const presentList = schedules.filter(
                item =>
                  item.check_in_time != "N/A" && item.check_out_time != "N/A"
              );
              this.summary.presentList = presentList;
              this.summary.totalPresent = presentList?.length;

              // calculate absent list
              const absentList = schedulesBeforeToday.filter(
                item =>
                  item.check_in_time == "N/A" || item.check_out_time == "N/A"
              );
              this.summary.absentList = absentList;
              this.summary.totalAbsent = absentList?.length;

              if (
                isFirst &&
                this.$route.query &&
                this.$route.query.type &&
                this.$route.query.type == "checkin"
              ) {
                setTimeout(() => {
                  this.handleCheckIn();
                }, 1000);
              }
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.dateNow) {
      this.dateNow = this.$moment(this.$route.query.dateNow);
    }
    this.getDetailSchedule(true);
  }
};
</script>
<style scoped>
.hovered:hover {
  cursor: pointer;
  opacity: 0.8;
}
</style>
