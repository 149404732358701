import BaseInstance from "../base.instance";
import {
  DASHBOARD_SUMMARY_CURRENT,
  DASHBOARD_PRESENT_LIST,
  DASHBOARD_BIRTHDAY_LIST,
  DASHBOARD_GRAFIK1,
  DASHBOARD_GRAFIK2,
  DASHBOARD_GRAFIK3,
  DASHBOARD_LIST_R_FILTER,
  DASHBOARD_REKAPITULASI,
  DASHBOARD_COUNT_PEGAWAI,
  DASHBOARD_SIMPEG
} from "../constants";

const DashboardService = {
  getSummaryCurrent(params) {
    return BaseInstance.query(DASHBOARD_SUMMARY_CURRENT, { params });
  },
  getPresentList(month, year) {
    return BaseInstance.fetch(DASHBOARD_PRESENT_LIST, `${month}/${year}`);
  },
  getBirthdayList(month, year) {
    return BaseInstance.fetch(DASHBOARD_BIRTHDAY_LIST, `${month}/${year}`);
  },
  getGrafik1(data) {
    return BaseInstance.post(DASHBOARD_GRAFIK1, data);
  },
  getGrafik2(data) {
    return BaseInstance.post(DASHBOARD_GRAFIK2, data);
  },
  getGrafik3(data) {
    return BaseInstance.post(DASHBOARD_GRAFIK3, data);
  },
  getListRFilter(params) {
    return BaseInstance.query(DASHBOARD_LIST_R_FILTER, { params });
  },
  getRekapitulasi(data) {
    return BaseInstance.post(DASHBOARD_REKAPITULASI, data);
  },
  getCountPegawai(data) {
    return BaseInstance.post(DASHBOARD_COUNT_PEGAWAI, data);
  },
  getDashboardSimpeg(data) {
    return BaseInstance.post(DASHBOARD_SIMPEG, data);
  }
};

export default DashboardService;
