<template>
  <div :id="`bar-chart-${id}`">
    <div id="chart-container">
      <fusioncharts
        :type="type"
        :width="width"
        :height="height"
        :dataformat="dataFormat"
        :dataSource="dataSource"
      >
      </fusioncharts>
    </div>
  </div>
</template>
<script>
import FusionCharts from "fusioncharts";
export default {
  props: {
    identity: { type: String, required: false },
    onClickPlot: {
      type: Function,
      required: false
    }
  },
  name: "app",
  data() {
    return {
      id: "asd",
      type: "column2d",
      renderAt: "chart-container",
      width: "100%",
      height: "350",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "",
          subCaption: "",
          numberPrefix: "",
          theme: "fusion"
        },
        data: []
      },
      listen: true,
      remove: false
    };
  },
  methods: {
    init(val, id) {
      this.id = id;
      this.dataSource.data = val;
      if (this.onClickPlot) {
        FusionCharts.addEventListener("dataPlotClick", this.handleClick);
      }
    },
    handleClick(e) {
      this.onClickPlot(e, this.id);
    }
  },
  created() {
    this.id = this.identity;
  }
};
</script>
