import BaseInstance from "../base.instance";
import {
  PEGAWAI_LIST,
  PEGAWAI_DETAIL,
  PEGAWAI_HISTORY_ATTENDANCE,
  PEGAWAI_SCHEDULES,
  PEGAWAI_SUMMARY,
  SIMPEG_PEGAWAI_SAVE,
  PEGAWAI_DIGITAL_LIST,
  PEGAWAI_DIGITAL_SAVE,
  PEGAWAI_DIGITAL_DELETE,
  PEGAWAI_HISTORY_LIST,
  PEGAWAI_HISTORY,
  PEGAWAI_HISTORY_DETAIL,
  PEGAWAI_HISTORY_APPROVE,
  PEGAWAI_HISTORY_DECLINE,
  PEGAWAI_RIWAYAT_LIST,
  SIMPEG_DATA_KELUARGA_SAVE,
  PEGAWAI_FAMILY_DELETE,
  PEGAWAI_CHECK_NIP_V2,
  PEGAWAI_LIST_APPROVED,
  PEGAWAI_APPROVED,
  PRESENSI_DATA_PRESENSI,
  PRESENSI_DATA_SCHEDULE_DETAIL,
  PRESENSI_DATA_PROFILE
} from "../constants";

const PegawaiService = {
  getList(data) {
    return BaseInstance.post(PEGAWAI_LIST, data);
  },
  getDetail(id) {
    return BaseInstance.fetch(PEGAWAI_DETAIL, id);
  },
  getHistoryAttendance(id, month, year) {
    return BaseInstance.fetch(
      PEGAWAI_HISTORY_ATTENDANCE,
      `${id}/${month}/${year}`
    );
  },
  getSchedules(id, month, year) {
    return BaseInstance.fetch(PEGAWAI_SCHEDULES, `${id}/${month}/${year}`);
  },
  getSummary(id, month, year) {
    return BaseInstance.fetch(PEGAWAI_SUMMARY, `${id}/${month}/${year}`);
  },
  save(id, data) {
    return BaseInstance.create(SIMPEG_PEGAWAI_SAVE, id, data);
  },
  create(data) {
    return BaseInstance.post(SIMPEG_PEGAWAI_SAVE, data);
  },
  getDigitalList(params) {
    return BaseInstance.query(PEGAWAI_DIGITAL_LIST, { params });
  },
  saveDigital(data) {
    return BaseInstance.post(PEGAWAI_DIGITAL_SAVE, data);
  },
  deleteDigital(data) {
    return BaseInstance.post(PEGAWAI_DIGITAL_DELETE, data);
  },
  getHistoryList(data) {
    return BaseInstance.post(PEGAWAI_HISTORY_LIST, data);
  },
  getHistoryDetail(id) {
    return BaseInstance.fetch(PEGAWAI_HISTORY, id);
  },
  approveHistory(id) {
    return BaseInstance.create(PEGAWAI_HISTORY_APPROVE, id);
  },
  declineHistory(id) {
    return BaseInstance.create(PEGAWAI_HISTORY_DECLINE, id);
  },
  getListRiwayatSummary(nip) {
    return BaseInstance.fetch(PEGAWAI_RIWAYAT_LIST, `${nip}`);
  },
  getListRiwayat(type, nip) {
    return BaseInstance.fetch(PEGAWAI_RIWAYAT_LIST, `${type}/${nip}`);
  },
  saveFamily(data) {
    return BaseInstance.post(SIMPEG_DATA_KELUARGA_SAVE, data);
  },
  deleteFamily(params) {
    return BaseInstance.remove(PEGAWAI_FAMILY_DELETE, { params });
  },
  checkNIP(params) {
    return BaseInstance.query(PEGAWAI_CHECK_NIP_V2, { params });
  },
  getApprovalList(data) {
    return BaseInstance.post(PEGAWAI_LIST_APPROVED, data);
  },
  approve(data) {
    return BaseInstance.post(PEGAWAI_APPROVED, data);
  },
  getDetailHistory(data) {
    return BaseInstance.post(PEGAWAI_HISTORY_DETAIL, data);
  },
  // New Env
  getListPresensi(data) {
    return BaseInstance.post(PRESENSI_DATA_PRESENSI, data);
  },
  getDataProfile(id) {
    return BaseInstance.fetch(PRESENSI_DATA_PROFILE, id);
  },
  getDetailSchedule(id, month, year) {
    return BaseInstance.fetch(
      PRESENSI_DATA_SCHEDULE_DETAIL,
      `${id}/${month}/${year}`
    );
  }
};

export default PegawaiService;
