<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-card color="#48A8F8" class="px-7 py-10 rounded-lg">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                {{ currentDate | dateDayDisplay }}
              </p>
              <p class="subtitle-2 white--text ma-0">
                {{ currentDate | date }}
              </p>
            </div>
            <v-avatar class="ma-1" tile>
              <v-icon size="70" color="white">event</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-card color="#48A8F8" class="px-7 py-10 rounded-lg">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                {{ summary.totalPegawai | number }}
              </p>
              <p class="subtitle-2 white--text ma-0">Jumlah Pegawai Aktif</p>
            </div>
            <v-avatar class="ma-1" tile>
              <v-icon size="70" color="white">person</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-1" v-if="currentUser.NIP">
      <v-col cols="12" xl="4" lg="4" md="6" sm="6" xs="12">
        <v-card
          color="#FBB005"
          class="px-7 py-10 rounded-lg hovered"
          @click="handleCheckIn"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                Presensi
              </p>
              <p class="subtitle-2 white--text ma-0">
                <span v-if="attendanceData.status1 != 100001">
                  Untuk isi presensi klik disini
                </span>
                <span v-else>
                  Hari libur
                </span>
              </p>
              <p class="subtitle-2 white--text ma-0">
                <span
                  >Jam Kerja: <b>{{ attendanceData.jam_kerja }}</b></span
                >
              </p>
            </div>
            <v-avatar class="ma-1">
              <v-icon size="70" color="white">fingerprint</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="6" sm="6" xs="12">
        <v-card color="#3AD581" class="px-7 py-10 rounded-lg">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                {{ summary.totalPresent | number }}
              </p>
              <p class="subtitle-2 white--text ma-0">
                Total Kehadiran di bulan
                <b>{{ dateNow | dateMonthDisplay }} {{ dateNow | dateYear }}</b>
              </p>
              <p class="subtitle-2 white--text ma-0">
                Untuk lihat riwayat klik
                <span
                  class="hover-text"
                  @click="
                    () =>
                      handleHistory('Riwayat Kehadiran', summary.presentList)
                  "
                  >disini</span
                >
              </p>
            </div>
            <v-avatar class="ma-1">
              <v-icon size="70" color="white">check_circle_outline</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="6" sm="6" xs="12">
        <v-card color="#ED7272" class="px-7 py-10 rounded-lg">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-h5 white--text font-weight-bold ma-0">
                {{ summary.totalAbsent | number }}
              </p>
              <p class="subtitle-2 white--text ma-0">
                Total Ketidakhadiran di bulan
                <b>{{ dateNow | dateMonthDisplay }} {{ dateNow | dateYear }}</b>
              </p>
              <p class="subtitle-2 white--text ma-0">
                Untuk lihat riwayat klik
                <span
                  class="hover-text"
                  @click="
                    () =>
                      handleHistory(
                        'Riwayat Ketidakhadiran',
                        summary.absentList
                      )
                  "
                  >disini</span
                >
              </p>
            </div>
            <v-avatar class="ma-1">
              <v-icon size="70" color="white">highlight_off</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-2" id="card-custom">
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
        <v-select
          placeholder="Unit Utama"
          :items="unitList"
          v-model="filter.unit_utama_id"
          :loading="loadingUnit"
          outlined
          dense
          hide-details
          clearable
          item-text="singkatan"
          item-value="unit_utama_id"
          @change="changeUnitList(0)"
          @click:clear="changeUnitList(0)"
        ></v-select>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
        <v-select
          placeholder="Unit Kerja Group"
          :items="unitList1"
          v-model="filter.uk_group_id"
          :loading="loadingUnit"
          outlined
          dense
          hide-details
          clearable
          item-text="nama_uk_group"
          item-value="uk_group_id"
          @change="changeUnitList(1)"
          @click:clear="changeUnitList(1)"
        ></v-select>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
        <v-select
          placeholder="Unit Kerja"
          :items="unitList2"
          v-model="filter.unit_kerja_2_id"
          :loading="loadingUnit"
          outlined
          dense
          hide-details
          clearable
          item-text="singkatan"
          item-value="unit_kerja_2_id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <v-card class="pa-4">
          <v-card-actions class="pa-0">
            <p class="title mb-0">Jenis Kelamin</p>
            <v-spacer />
            <div class="py-1">
              <p class="mb-0 caption" v-if="displayFilterUnitKerja">
                <b>Unit Kerja 2: </b>{{ displayFilterUnitKerja }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitGroup">
                <b>Unit Group: </b>{{ displayFilterUnitGroup }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitUtama">
                <b>Unit Utama: </b>{{ displayFilterUnitUtama }}
              </p>
            </div>
          </v-card-actions>
          <p class="subtitle mb-0">
            <span
              class="hover-primary"
              @click="handleResetJenisKelamin"
              v-if="selectedJenisKelamin"
              >Jenis Kelamin</span
            >
            <span v-else>Jenis Kelamin</span>
            <template v-if="selectedJenisKelamin">
              <span class="mx-1">></span>
              <span
                class="hover-primary"
                @click="handleResetJenisKelamin2"
                v-if="selectedJenisKelamin2"
              >
                {{ selectedJenisKelamin.keterangan }}</span
              >
              <span v-else> {{ selectedJenisKelamin.keterangan }}</span>
            </template>
            <template v-if="selectedJenisKelamin2">
              <span class="mx-1">></span>
              {{ selectedJenisKelamin2.keterangan }}
            </template>
          </p>
          <pie-single-chart
            ref="pieSingleJenisKelamin"
            id="JK"
            :onClickPlot="handleClickJenisKelamin"
            v-show="!selectedJenisKelamin2"
          />
          <employee-dashboard-table
            :employees="dataJenisKelamin"
            v-show="selectedJenisKelamin2"
          />
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <v-card class="pa-4">
          <v-card-actions class="pa-0">
            <p class="title mb-0">Usia</p>
            <v-spacer />
            <div class="py-1">
              <p class="mb-0 caption" v-if="displayFilterUnitKerja">
                <b>Unit Kerja 2: </b>{{ displayFilterUnitKerja }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitGroup">
                <b>Unit Group: </b>{{ displayFilterUnitGroup }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitUtama">
                <b>Unit Utama: </b>{{ displayFilterUnitUtama }}
              </p>
            </div>
          </v-card-actions>
          <p class="subtitle mb-0">
            <span
              class="hover-primary"
              @click="handleResetUsia"
              v-if="selectedUsia"
              >Usia</span
            >
            <span v-else>Usia</span>
            <template v-if="selectedUsia">
              <span class="mx-1">></span>
              <span
                class="hover-primary"
                @click="handleResetUsia2"
                v-if="selectedUsia2"
              >
                {{ selectedUsia.keterangan }}</span
              >
              <span v-else> {{ selectedUsia.keterangan }}</span>
            </template>
            <template v-if="selectedUsia2">
              <span class="mx-1">></span>
              {{ selectedUsia2.keterangan }}
            </template>
          </p>
          <pie-single-chart
            ref="pieSingleUsia"
            id="GL"
            :onClickPlot="handleClickUsia"
            v-show="!selectedUsia2"
          />
          <employee-dashboard-table
            :employees="dataUsia"
            v-show="selectedUsia2"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card class="pa-4">
          <v-card-actions class="pa-0">
            <p class="title mb-0">Jabatan</p>
            <v-spacer />
            <div class="py-1">
              <p class="mb-0 caption" v-if="displayFilterUnitKerja">
                <b>Unit Kerja 2: </b>{{ displayFilterUnitKerja }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitGroup">
                <b>Unit Group: </b>{{ displayFilterUnitGroup }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitUtama">
                <b>Unit Utama: </b>{{ displayFilterUnitUtama }}
              </p>
            </div>
          </v-card-actions>
          <p class="subtitle mb-0">
            <span
              class="hover-primary"
              @click="handleResetJabatan"
              v-if="selectedJabatan"
              >Jabatan</span
            >
            <span v-else>Jabatan</span>
            <template v-if="selectedJabatan">
              <span class="mx-1">></span>
              <span
                class="hover-primary"
                @click="handleResetJabatan2"
                v-if="selectedJabatan2"
              >
                {{ selectedJabatan.keterangan }}</span
              >
              <span v-else> {{ selectedJabatan.keterangan }}</span>
            </template>
            <template v-if="selectedJabatan2">
              <span class="mx-1">></span>
              <span
                class="hover-primary"
                @click="handleResetJabatan3"
                v-if="selectedJabatan3"
              >
                {{ selectedJabatan2.keterangan }}</span
              >
              <span v-else> {{ selectedJabatan2.keterangan }}</span>
            </template>
            <template v-if="selectedJabatan3">
              <span class="mx-1">></span>
              {{ selectedJabatan3.keterangan }}
            </template>
          </p>
          <div>
            <v-text-field
              v-model="searchJabatan"
              placeholder="Cari"
              outlined
              dense
              hide-details
              prepend-inner-icon="search"
              class="mt-2"
            ></v-text-field>
            <v-simple-table height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      No
                    </th>
                    <th class="text-left">
                      Jabatan
                    </th>
                    <th class="text-left">
                      Kebutuhan
                    </th>
                    <th class="text-left">
                      Jumlah Pegawai
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, index) in dataJabatanBySearch"
                    :key="index"
                    @click="() => handleClickJabatan(data)"
                    style="cursor: pointer"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ data.keterangan }}</td>
                    <td>{{ data.kebutuhan }}</td>
                    <td>{{ data.jumlah }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <!-- <bar-single-chart
            ref="barSingleJabatan"
            id="JB"
            :onClickPlot="handleClickJabatan"
            v-show="
              typeJabatan == 'UK'
            "
          />
          <employee-dashboard-table
            :employees="dataJabatan"
            v-show="typeJabatan == 'UK3' && selectedJabatan3"
          /> -->
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card class="pa-4">
          <v-card-actions class="pa-0">
            <p class="subtitle mb-0">
              <span
                class="hover-primary"
                @click="handleResetGolongan"
                v-if="selectedGolongan"
                >Golongan</span
              >
              <span v-else>Golongan</span>
              <template v-if="selectedGolongan">
                <span class="mx-1">></span>
                <span
                  class="hover-primary"
                  @click="handleResetGolongan2"
                  v-if="selectedGolongan2"
                >
                  {{ selectedGolongan.keterangan }}</span
                >
                <span v-else> {{ selectedGolongan.keterangan }}</span>
              </template>
              <template v-if="selectedGolongan2">
                <span class="mx-1">></span>
                <span
                  class="hover-primary"
                  @click="handleResetGolongan3"
                  v-if="selectedGolongan3"
                >
                  {{ selectedGolongan2.keterangan }}</span
                >
                <span v-else> {{ selectedGolongan2.keterangan }}</span>
              </template>
              <template v-if="selectedGolongan3">
                <span class="mx-1">></span>
                {{ selectedGolongan3.keterangan }}
              </template>
            </p>
            <v-spacer />
            <div class="py-1">
              <p class="mb-0 caption" v-if="displayFilterUnitKerja">
                <b>Unit Kerja 2: </b>{{ displayFilterUnitKerja }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitGroup">
                <b>Unit Group: </b>{{ displayFilterUnitGroup }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitUtama">
                <b>Unit Utama: </b>{{ displayFilterUnitUtama }}
              </p>
            </div>
          </v-card-actions>
          <bar-single-chart
            ref="barSingleGolongan"
            id="GL"
            :onClickPlot="handleClickGolongan"
            v-show="!selectedGolongan3"
          />
          <employee-dashboard-table
            :employees="dataGolongan"
            v-show="selectedGolongan3"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card class="pa-4">
          <v-card-actions class="pa-0">
            <p class="subtitle mb-0">
              <span
                class="hover-primary"
                @click="handleResetPendidikan"
                v-if="selectedPendidikan"
                >Pendidikan</span
              >
              <span v-else>Pendidikan</span>
              <template v-if="selectedPendidikan">
                <span class="mx-1">></span>
                <span
                  class="hover-primary"
                  @click="handleResetPendidikan2"
                  v-if="selectedPendidikan2"
                >
                  {{ selectedPendidikan.keterangan }}</span
                >
                <span v-else> {{ selectedPendidikan.keterangan }}</span>
              </template>
              <template v-if="selectedPendidikan2">
                <span class="mx-1">></span>
                <span
                  class="hover-primary"
                  @click="handleResetPendidikan3"
                  v-if="selectedPendidikan3"
                >
                  {{ selectedPendidikan2.keterangan }}</span
                >
                <span v-else> {{ selectedPendidikan2.keterangan }}</span>
              </template>
              <template v-if="selectedPendidikan3">
                <span class="mx-1">></span>
                {{ selectedPendidikan3.keterangan }}
              </template>
            </p>
            <v-spacer />
            <div class="py-1">
              <p class="mb-0 caption" v-if="displayFilterUnitKerja">
                <b>Unit Kerja 2: </b>{{ displayFilterUnitKerja }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitGroup">
                <b>Unit Group: </b>{{ displayFilterUnitGroup }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitUtama">
                <b>Unit Utama: </b>{{ displayFilterUnitUtama }}
              </p>
            </div>
          </v-card-actions>
          <bar-single-chart
            ref="barSinglePendidikan"
            id="PD"
            :onClickPlot="handleClickPendidikan"
            v-show="!selectedPendidikan3"
          />
          <employee-dashboard-table
            :employees="dataPendidikan"
            v-show="selectedPendidikan3"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card class="pa-4">
          <v-card-actions class="pa-0">
            <p class="subtitle mb-0">
              <span
                class="hover-primary"
                @click="handleResetMasaKerja"
                v-if="selectedMasaKerja"
                >Masa Kerja</span
              >
              <span v-else>Masa Kerja</span>
              <template v-if="selectedMasaKerja">
                <span class="mx-1">></span>
                <span
                  class="hover-primary"
                  @click="handleResetMasaKerja2"
                  v-if="selectedMasaKerja2"
                >
                  {{ selectedMasaKerja.keterangan }}</span
                >
                <span v-else> {{ selectedMasaKerja.keterangan }}</span>
              </template>
              <template v-if="selectedMasaKerja2">
                <span class="mx-1">></span>
                <span
                  class="hover-primary"
                  @click="handleResetMasaKerja3"
                  v-if="selectedMasaKerja3"
                >
                  {{ selectedMasaKerja2.keterangan }}</span
                >
                <span v-else> {{ selectedMasaKerja2.keterangan }}</span>
              </template>
              <template v-if="selectedMasaKerja3">
                <span class="mx-1">></span>
                {{ selectedMasaKerja3.keterangan }}
              </template>
            </p>
            <v-spacer />
            <div class="py-1">
              <p class="mb-0 caption" v-if="displayFilterUnitKerja">
                <b>Unit Kerja 2: </b>{{ displayFilterUnitKerja }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitGroup">
                <b>Unit Group: </b>{{ displayFilterUnitGroup }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitUtama">
                <b>Unit Utama: </b>{{ displayFilterUnitUtama }}
              </p>
            </div>
          </v-card-actions>
          <bar-single-chart
            ref="barSingleMasaKerja"
            id="MK"
            :onClickPlot="handleClickMasaKerja"
            v-show="!selectedMasaKerja3"
          />
          <employee-dashboard-table
            :employees="dataMasaKerja"
            v-show="selectedMasaKerja3"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card class="pa-4">
          <v-card-actions class="pa-0">
            <p class="subtitle mb-0">
              <span
                class="hover-primary"
                @click="handleResetSatker"
                v-if="selectedSatker"
                >Satker</span
              >
              <span v-else>Satker</span>
              <template v-if="selectedSatker">
                <span class="mx-1">></span>
                <span
                  class="hover-primary"
                  @click="handleResetSatker2"
                  v-if="selectedSatker2"
                >
                  {{ selectedSatker.keterangan }}</span
                >
                <span v-else> {{ selectedSatker.keterangan }}</span>
              </template>
              <template v-if="selectedSatker2">
                <span class="mx-1">></span>
                {{ selectedSatker2.keterangan }}
              </template>
            </p>
            <v-spacer />
            <div class="py-1">
              <p class="mb-0 caption" v-if="displayFilterUnitKerja">
                <b>Unit Kerja 2: </b>{{ displayFilterUnitKerja }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitGroup">
                <b>Unit Group: </b>{{ displayFilterUnitGroup }}
              </p>
              <p class="mb-0 caption" v-else-if="displayFilterUnitUtama">
                <b>Unit Utama: </b>{{ displayFilterUnitUtama }}
              </p>
            </div>
          </v-card-actions>
          <bar-single-chart
            ref="barSingleSatker"
            id="MK"
            :onClickPlot="handleClickSatker"
            v-show="!selectedSatker2"
          />
          <employee-dashboard-table
            :employees="dataSatker"
            v-show="selectedSatker2"
          />
        </v-card>
      </v-col>
    </v-row>
    <AbsenDialog @on-refresh="getDetailSchedule" ref="absen" />
    <ListAttendanceDialog ref="history" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardService from "@/services/resources/dashboard.service";
import DataUtamaService from "@/services/resources/data.utama.service";
import PegawaiService from "@/services/resources/pegawai.service";
import PieSingleChart from "@/components/Chart/PieSingle";
import BarSingleChart from "@/components/Chart/BarSingle";
import EmployeeDashboardTable from "@/components/Table/EmployeeDashboardTable";
const AbsenDialog = () => import("@/components/Dialog/Absen");
const ListAttendanceDialog = () => import("@/components/Dialog/ListAttendance");

export default {
  components: {
    PieSingleChart,
    BarSingleChart,
    EmployeeDashboardTable,
    AbsenDialog,
    ListAttendanceDialog
  },
  data() {
    return {
      menu: false,
      currentDate: new Date(),
      date: new Date().toISOString().substr(0, 7),
      dateNow: this.$moment(),
      loading: false,
      loadingUnit: false,
      searchJabatan: "",
      summary: {
        totalPegawai: 0,
        totalPresent: 0,
        totalAbsent: 0,
        totalDay: 0,
        presentList: [],
        absentList: []
      },
      attendanceData: {
        NIP: null,
        check_in_time: null,
        check_out_time: null,
        jam_kerja: null,
        jam_kerja_from: null,
        jam_kerja_to: null,
        status1: null,
        status1_name: null
      },
      filter: {
        unit_utama_id: null,
        uk_group_id: null,
        unit_kerja_2_id: null
      },
      unitList: [],
      unitList1: [],
      unitList2: [],
      typeJenisKelamin: "JK",
      dataJenisKelamin: [],
      selectedJenisKelamin: null,
      selectedJenisKelamin2: null,
      typeUsia: "UA",
      dataUsia: [],
      selectedUsia: null,
      selectedUsia2: null,
      typeJabatan: "JB",
      dataJabatan: [],
      selectedJabatan: null,
      selectedJabatan2: null,
      selectedJabatan3: null,
      typeGolongan: "GL",
      dataGolongan: [],
      selectedGolongan: null,
      selectedGolongan2: null,
      selectedGolongan3: null,
      typePendidikan: "PD",
      dataPendidikan: [],
      selectedPendidikan: null,
      selectedPendidikan2: null,
      selectedPendidikan3: null,
      typeMasaKerja: "MK",
      dataMasaKerja: [],
      selectedMasaKerja: null,
      selectedMasaKerja2: null,
      selectedMasaKerja3: null,
      typeSatker: "MK",
      dataSatker: [],
      selectedSatker: null,
      selectedSatker2: null
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isAroundWorkingHour() {
      const { jam_kerja_from, jam_kerja_to } = this.attendanceData;
      if (!jam_kerja_from || !jam_kerja_to) return;
      const isAfterNow = this.$moment().isAfter(
        `${this.$moment().format("YYYY-MM-DD")} ${jam_kerja_from}`
      );
      const isBeforeNow = this.$moment().isBefore(
        `${this.$moment().format("YYYY-MM-DD")} ${jam_kerja_to}`
      );
      return isAfterNow && isBeforeNow;
    },
    filterUnit() {
      return [
        this.filter.unit_utama_id,
        this.filter.uk_group_id,
        this.filter.unit_kerja_2_id,
        this.date
      ].join();
    },
    dataJabatanBySearch() {
      return this.dataJabatan.filter(d =>
        d.keterangan.toLowerCase().includes(this.searchJabatan.toLowerCase())
      );
    },
    displayFilterUnitUtama() {
      if (!this.filter.unit_utama_id) return null;
      const selectedUnit = this.unitList.find(
        data => data.unit_utama_id === this.filter.unit_utama_id
      );
      return selectedUnit?.singkatan;
    },
    displayFilterUnitGroup() {
      if (!this.filter.uk_group_id) return null;
      const selectedUnit = this.unitList1.find(
        data => data.uk_group_id === this.filter.uk_group_id
      );
      return selectedUnit?.nama_uk_group;
    },
    displayFilterUnitKerja() {
      if (!this.filter.unit_kerja_2_id) return null;
      const selectedUnit = this.unitList2.find(
        data => data.unit_kerja_2_id === this.filter.unit_kerja_2_id
      );
      return selectedUnit?.singkatan;
    }
  },
  watch: {
    filterUnit: function() {
      this.initPage();
    }
  },
  methods: {
    changeUnitList(type = 0) {
      if (type == 0) {
        this.unitList1 = this.unitList.find(
          d => d.unit_utama_id == this.filter.unit_utama_id
        )?.unit_kerja_group;
        this.filter.uk_group_id = null;
        this.filter.unit_kerja_2_id = null;
      } else if (type == 1) {
        this.unitList2 = this.unitList1.find(
          d => d.uk_group_id == this.filter.uk_group_id
        )?.unit_kerja2;
        this.filter.unit_kerja_2_id = null;
      }
    },
    initPage() {
      this.handleResetJenisKelamin();
      this.handleResetUsia();
      this.handleResetGolongan();
      this.handleResetJabatan();
      this.handleResetPendidikan();
      this.handleResetMasaKerja();
      this.handleResetSatker();
    },
    handleResetJenisKelamin() {
      this.selectedJenisKelamin = null;
      this.selectedJenisKelamin2 = null;
      this.getJenisKelamin();
    },
    handleResetJenisKelamin2() {
      this.selectedJenisKelamin2 = null;
      this.getJenisKelamin("KJ", this.selectedJenisKelamin.code);
    },
    handleClickJenisKelamin(e, type) {
      console.log("click JK", type);
      const selectedJenisKelamin = this.dataJenisKelamin.find(
        d => d.keterangan == e.data.categoryLabel
      );
      if (selectedJenisKelamin) {
        if (this.typeJenisKelamin == "JK") {
          this.selectedJenisKelamin = selectedJenisKelamin;
          this.getJenisKelamin("KJ", this.selectedJenisKelamin.code);
        } else if (this.typeJenisKelamin == "KJ") {
          this.selectedJenisKelamin2 = selectedJenisKelamin;
          this.getJenisKelamin(
            "UK",
            this.selectedJenisKelamin.code,
            this.selectedJenisKelamin2.code,
            false
          );
        }
      }
    },
    handleResetUsia() {
      this.selectedUsia = null;
      this.selectedUsia2 = null;
      this.getUsia();
    },
    handleResetUsia2() {
      this.selectedUsia2 = null;
      this.getUsia();
    },
    handleClickUsia(e, type) {
      console.log("click UA", type);
      const selectedUsia = this.dataUsia.find(
        d => d.keterangan == e.data.categoryLabel
      );
      if (selectedUsia) {
        if (this.typeUsia == "UA") {
          this.selectedUsia = selectedUsia;
          this.getUsia("KJ", this.selectedUsia.code);
        } else if (this.typeUsia == "KJ") {
          this.selectedUsia2 = selectedUsia;
          this.getUsia(
            "UK",
            this.selectedUsia.code,
            this.selectedUsia2.code,
            false
          );
        }
      }
    },
    handleResetGolongan() {
      this.selectedGolongan = null;
      this.selectedGolongan2 = null;
      this.getGolongan();
    },
    handleResetGolongan2() {
      this.selectedGolongan2 = null;
      this.selectedGolongan3 = null;
      this.getGolongan("UK", this.selectedGolongan.code);
    },
    handleResetGolongan3() {
      this.selectedGolongan3 = null;
      this.getGolongan(
        "UK3",
        this.selectedGolongan.code,
        this.selectedGolongan2.code
      );
    },
    handleClickGolongan(e, type) {
      console.log("click GL", type);
      const selectedGolongan = this.dataGolongan.find(
        d => d.keterangan == e.data.categoryLabel
      );
      if (selectedGolongan) {
        if (this.typeGolongan == "GL") {
          this.selectedGolongan = selectedGolongan;
          this.getGolongan("UK", this.selectedGolongan.code);
        } else if (this.typeGolongan == "UK") {
          this.selectedGolongan2 = selectedGolongan;
          this.getGolongan(
            "UK3",
            this.selectedGolongan.code,
            this.selectedGolongan2.code
          );
        } else if (this.typeGolongan == "UK3") {
          this.selectedGolongan3 = selectedGolongan;
          this.getGolongan(
            "UK3",
            this.selectedGolongan.code,
            this.selectedGolongan2.code,
            this.selectedGolongan3.code,
            false
          );
        }
      }
    },
    handleResetJabatan() {
      this.selectedJabatan = null;
      this.selectedJabatan2 = null;
      this.selectedJabatan3 = null;
      this.getJabatan();
    },
    handleResetJabatan2() {
      this.selectedJabatan2 = null;
      this.selectedJabatan3 = null;
      this.getJabatan("UK", this.selectedJabatan.code);
    },
    handleResetJabatan3() {
      this.selectedJabatan3 = null;
      this.getJabatan(
        "UK3",
        this.selectedJabatan.code,
        this.selectedJabatan2.code
      );
    },
    handleClickJabatan(e, type) {
      console.log("click JB", type);
      const keterangan = e.keterangan || e.data.categoryLabel;
      const selectedJabatan = this.dataJabatan.find(
        d => d.keterangan == keterangan
      );
      if (selectedJabatan) {
        if (this.typeJabatan == "JB") {
          this.selectedJabatan = selectedJabatan;
          this.getJabatan("UK", e.code);
        } else if (this.typeJabatan == "UK") {
          this.selectedJabatan2 = selectedJabatan;
          this.getJabatan(
            "UK3",
            this.selectedJabatan.code,
            this.selectedJabatan2.code
          );
        } else if (this.typeJabatan == "UK3") {
          this.selectedJabatan3 = selectedJabatan;
          this.getJabatan(
            "UK3",
            this.selectedJabatan.code,
            this.selectedJabatan2.code,
            this.selectedJabatan3.code,
            false
          );
        }
      }
    },
    handleResetPendidikan() {
      this.selectedPendidikan = null;
      this.selectedPendidikan2 = null;
      this.getPendidikan();
    },
    handleResetPendidikan2() {
      this.selectedPendidikan2 = null;
      this.selectedPendidikan3 = null;
      this.getPendidikan("UK", this.selectedPendidikan.code);
    },
    handleResetPendidikan3() {
      this.selectedPendidikan3 = null;
      this.getPendidikan(
        "UK3",
        this.selectedPendidikan.code,
        this.selectedPendidikan2.code
      );
    },
    handleClickPendidikan(e, type) {
      console.log("click PD", type);
      const selectedPendidikan = this.dataPendidikan.find(
        d => d.keterangan == e.data.categoryLabel
      );
      if (selectedPendidikan) {
        if (this.typePendidikan == "PD") {
          this.selectedPendidikan = selectedPendidikan;
          this.getPendidikan("UK", this.selectedPendidikan.code);
        } else if (this.typePendidikan == "UK") {
          this.selectedPendidikan2 = selectedPendidikan;
          this.getPendidikan(
            "UK3",
            this.selectedPendidikan.code,
            this.selectedPendidikan2.code
          );
        } else if (this.typePendidikan == "UK3") {
          this.selectedPendidikan3 = selectedPendidikan;
          this.getPendidikan(
            "UK3",
            this.selectedPendidikan.code,
            this.selectedPendidikan2.code,
            this.selectedPendidikan3.code,
            false
          );
        }
      }
    },
    handleResetMasaKerja() {
      this.selectedMasaKerja = null;
      this.selectedMasaKerja2 = null;
      this.selectedMasaKerja3 = null;
      this.getMasaKerja();
    },
    handleResetMasaKerja2() {
      this.selectedMasaKerja2 = null;
      this.selectedMasaKerja3 = null;
      this.getMasaKerja("UK", this.selectedMasaKerja.code);
    },
    handleResetMasaKerja3() {
      this.selectedMasaKerja3 = null;
      this.getMasaKerja(
        "UK3",
        this.selectedMasaKerja.code,
        this.selectedMasaKerja2.code
      );
    },
    handleClickMasaKerja(e, type) {
      console.log("click MK", type);
      const selectedMasaKerja = this.dataMasaKerja.find(
        d => d.keterangan == e.data.categoryLabel
      );
      if (selectedMasaKerja) {
        if (this.typeMasaKerja == "MK") {
          this.selectedMasaKerja = selectedMasaKerja;
          this.getMasaKerja("UK", this.selectedMasaKerja.code);
        } else if (this.typeMasaKerja == "UK") {
          this.selectedMasaKerja2 = selectedMasaKerja;
          this.getMasaKerja(
            "UK3",
            this.selectedMasaKerja.code,
            this.selectedMasaKerja2.code
          );
        } else if (this.typeMasaKerja == "UK3") {
          this.selectedMasaKerja3 = selectedMasaKerja;
          this.getMasaKerja(
            "UK3",
            this.selectedMasaKerja.code,
            this.selectedMasaKerja2.code,
            this.selectedMasaKerja3.code,
            false
          );
        }
      }
    },
    handleResetSatker() {
      this.selectedSatker = null;
      this.selectedSatker2 = null;
      this.getSatker();
    },
    handleResetSatker2() {
      this.selectedSatker2 = null;
      this.getSatker("UK3", this.selectedSatker.code);
    },
    handleClickSatker(e, type) {
      console.log("click UK", type);
      const selectedSatker = this.dataSatker.find(
        d => d.keterangan == e.data.categoryLabel
      );
      if (selectedSatker) {
        if (this.typeSatker == "UK") {
          this.selectedSatker = selectedSatker;
          this.getSatker("UK3", this.selectedSatker.code);
        } else if (this.typeSatker == "UK3") {
          this.selectedSatker2 = selectedSatker;
          this.getSatker(
            "UK3",
            this.selectedSatker.code,
            this.selectedSatker2.code,
            false
          );
        }
      }
    },
    // Service
    async getListUnitKerjaGroup() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaGroupLOV(0)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.unitList = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getCountPegawai() {
      try {
        this.loadingUnit = true;
        await DashboardService.getCountPegawai({
          filter: {
            unit_utama_id: "",
            uk_group_id: "",
            unit_kerja_2_id: ""
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.summary.totalPegawai = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getJenisKelamin(
      jenis_grafik = "JK",
      kelamin = "",
      kategori = "",
      grafik = true
    ) {
      this.typeJenisKelamin = jenis_grafik;
      try {
        this.loadingUnit = true;
        await DashboardService.getDashboardSimpeg({
          grafik,
          filter: {
            jenis_grafik,
            unit_utama_id: this.filter.unit_utama_id,
            uk_group_id: this.filter.uk_group_id,
            unit_kerja_2_id: this.filter.unit_kerja_2_id,
            kelamin,
            kategori
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.dataJenisKelamin = data;
              const graphData = data.map(d => {
                return {
                  code: d.code,
                  label: d.keterangan,
                  value: d.jumlah
                };
              });
              this.$refs.pieSingleJenisKelamin.init(
                graphData,
                jenis_grafik == "JK" ? "Jenis Kelamin" : "Jabatan",
                "JK"
              );
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getUsia(
      jenis_grafik = "UA",
      usia = "",
      kategori = "",
      grafik = true
    ) {
      this.typeUsia = jenis_grafik;
      try {
        this.loadingUnit = true;
        await DashboardService.getDashboardSimpeg({
          grafik,
          filter: {
            jenis_grafik,
            unit_utama_id: this.filter.unit_utama_id,
            uk_group_id: this.filter.uk_group_id,
            unit_kerja_2_id: this.filter.unit_kerja_2_id,
            usia,
            kategori
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.dataUsia = data;
              const graphData = data.map(d => {
                return {
                  code: d.code,
                  label: d.keterangan,
                  value: d.jumlah
                };
              });
              this.$refs.pieSingleUsia.init(
                graphData,
                jenis_grafik == "UA" ? "Usia" : "Jabatan",
                "UA"
              );
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getGolongan(
      jenis_grafik = "GL",
      gol = "",
      unit_kerja = "",
      unit_kerja_3 = "",
      grafik = true
    ) {
      this.typeGolongan = jenis_grafik;
      try {
        this.loadingUnit = true;
        await DashboardService.getDashboardSimpeg({
          grafik,
          filter: {
            jenis_grafik,
            unit_utama_id: this.filter.unit_utama_id,
            uk_group_id: this.filter.uk_group_id,
            unit_kerja_2_id: this.filter.unit_kerja_2_id,
            gol,
            unit_kerja,
            unit_kerja_3
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.dataGolongan = data;
              const graphData = data.map(d => {
                return {
                  code: d.code,
                  label: d.keterangan,
                  value: d.jumlah
                };
              });
              this.$refs.barSingleGolongan.init(graphData, "GL");
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getJabatan(
      jenis_grafik = "JB",
      jabatan = "",
      unit_kerja = "",
      unit_kerja_3 = "",
      grafik = true
    ) {
      this.typeJabatan = jenis_grafik;
      try {
        this.loadingUnit = true;
        await DashboardService.getDashboardSimpeg({
          grafik,
          filter: {
            jenis_grafik,
            unit_utama_id: this.filter.unit_utama_id,
            uk_group_id: this.filter.uk_group_id,
            unit_kerja_2_id: this.filter.unit_kerja_2_id,
            jabatan,
            unit_kerja,
            unit_kerja_3
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.dataJabatan = data;
              const graphData = data.map(d => {
                return {
                  code: d.code,
                  label: d.keterangan,
                  value: d.jumlah
                };
              });
              this.$refs.barSingleJabatan.init(graphData, "JB");
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getPendidikan(
      jenis_grafik = "PD",
      pendidikan = "",
      unit_kerja = "",
      unit_kerja_3 = "",
      grafik = true
    ) {
      this.typePendidikan = jenis_grafik;
      try {
        this.loadingUnit = true;
        await DashboardService.getDashboardSimpeg({
          grafik,
          filter: {
            jenis_grafik,
            unit_utama_id: this.filter.unit_utama_id,
            uk_group_id: this.filter.uk_group_id,
            unit_kerja_2_id: this.filter.unit_kerja_2_id,
            pendidikan,
            unit_kerja,
            unit_kerja_3
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.dataPendidikan = data;
              const graphData = data.map(d => {
                return {
                  code: d.code,
                  label: d.keterangan,
                  value: d.jumlah
                };
              });
              this.$refs.barSinglePendidikan.init(graphData, "PD");
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getMasaKerja(
      jenis_grafik = "MK",
      masa_kerja = "",
      unit_kerja = "",
      unit_kerja_3 = "",
      grafik = true
    ) {
      this.typeMasaKerja = jenis_grafik;
      try {
        this.loadingUnit = true;
        await DashboardService.getDashboardSimpeg({
          grafik,
          filter: {
            jenis_grafik,
            unit_utama_id: this.filter.unit_utama_id,
            uk_group_id: this.filter.uk_group_id,
            unit_kerja_2_id: this.filter.unit_kerja_2_id,
            masa_kerja,
            unit_kerja,
            unit_kerja_3
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.dataMasaKerja = data;
              const graphData = data.map(d => {
                return {
                  code: d.code,
                  label: d.keterangan,
                  value: d.jumlah
                };
              });
              this.$refs.barSingleMasaKerja.init(graphData, "MK");
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getSatker(
      jenis_grafik = "UK",
      unit_kerja = "",
      unit_kerja_3 = "",
      grafik = true
    ) {
      this.typeSatker = jenis_grafik;
      try {
        this.loadingUnit = true;
        await DashboardService.getDashboardSimpeg({
          grafik,
          filter: {
            jenis_grafik,
            unit_utama_id: this.filter.unit_utama_id,
            uk_group_id: this.filter.uk_group_id,
            unit_kerja_2_id: this.filter.unit_kerja_2_id,
            unit_kerja,
            unit_kerja_3
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.dataSatker = data;
              const graphData = data.map(d => {
                return {
                  code: d.code,
                  label: d.keterangan,
                  value: d.jumlah
                };
              });
              this.$refs.barSingleSatker.init(graphData, "UK");
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    // Presensi
    handleCheckIn() {
      this.$refs.absen.insert({
        NIP: this.currentUser.NIP,
        nama: this.currentUser.name,
        attendance_date: this.$moment(this.dateNow).format("YYYY-MM-DD")
      });
    },
    handleHistory(title, list) {
      this.$refs.history.view({
        title,
        historyAttendances: list
      });
    },
    // Service
    async getDetailSchedule(isFirst) {
      try {
        this.loadingUnit = true;
        await PegawaiService.getDetailSchedule(
          this.currentUser.NIP,
          this.$options.filters.dateMonth(this.dateNow),
          this.$options.filters.dateYear(this.dateNow)
        )
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              const { data_schedule } = data;
              const schedulesNow = data_schedule.find(
                item =>
                  item.attendance_date ==
                  this.$moment(this.dateNow).format("YYYY-MM-DD")
              );
              if (schedulesNow) {
                const [from, to] = schedulesNow.jam_kerja.split("-");
                this.attendanceData = this.$_.merge(this.attendanceData, {
                  ...schedulesNow,
                  jam_kerja_from: from,
                  jam_kerja_to: to
                });
              }
              const schedules = data_schedule.filter(
                item =>
                  item.status1 != 100001 &&
                  (item.schedule_type == "WFO" || item.schedule_type == "WFH")
              );
              const schedulesBeforeToday = schedules.filter(item =>
                this.$moment(item.attendance_date).isBefore(this.dateNow)
              );
              this.summary.totalDay = schedulesBeforeToday.length;

              // calculate present list
              const presentList = schedules.filter(
                item =>
                  item.check_in_time != "N/A" && item.check_out_time != "N/A"
              );
              this.summary.presentList = presentList;
              this.summary.totalPresent = presentList?.length;

              // calculate absent list
              const absentList = schedulesBeforeToday.filter(
                item =>
                  item.check_in_time == "N/A" || item.check_out_time == "N/A"
              );
              this.summary.absentList = absentList;
              this.summary.totalAbsent = absentList?.length;

              if (
                isFirst &&
                this.$route.query &&
                this.$route.query.type &&
                this.$route.query.type == "checkin"
              ) {
                setTimeout(() => {
                  this.handleCheckIn();
                }, 1000);
              }
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnitKerjaGroup();
    this.getCountPegawai();
    this.initPage();
    if (this.$route.query && this.$route.query.dateNow) {
      this.dateNow = this.$moment(this.$route.query.dateNow);
    }
    this.getDetailSchedule(true);
  }
};
</script>
<style scoped>
.hovered:hover {
  cursor: pointer;
  opacity: 0.8;
}
</style>
